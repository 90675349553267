<template>
  <el-card class="accountProgress">
    <template #header>
      <div class="cardHeader">
        <span class="SubHead">开户进度</span>
        <el-select v-model="openAccountValue" size="small" style="width: 110px">
          <el-option label="Facebook" value="1" />
          <el-option label="TikTok" value="2" />
        </el-select>
      </div>
    </template>
    <ul class="openProgress">
      <li>
        <p class="Text proTitle">审核中</p>
        <p class="proNum" v-if="openAccountValue === '1'">{{ advertising.fb.review_num }}</p>
        <p class="proNum" v-if="openAccountValue === '2'">{{ advertising.tt.review_num }}</p>
      </li>
      <li>
        <p class="Text proTitle">待修改</p>
        <p class="proNum" v-if="openAccountValue === '1'">{{ advertising.fb.revise_num }}</p>
        <p class="proNum" v-if="openAccountValue === '2'">{{ advertising.tt.revise_num }}</p>
      </li>
      <li>
        <p class="Text proTitle">近7天已通过</p>
        <p class="proNum" v-if="openAccountValue === '1'">{{ advertising.fb.pass_num }}</p>
        <p class="proNum" v-if="openAccountValue === '2'">{{ advertising.tt.pass_num }}</p>
      </li>
    </ul>
    <ul class="accountRisk">
      <li>
        <p class="Text proTitle">广告违规风险评估</p>
      </li>
      <li>
        <p class="Text proTitle" style="height: 37px; line-height: 37px">风险等级</p>
        <p class="rank" :style="{ color: rankColor(advertising.violation) }" v-if="openAccountValue === '1'">
          {{ advertising.violation }}
        </p>
        <p class="noneRank" v-if="openAccountValue === '2'">暂无评估</p>
      </li>
    </ul>
  </el-card>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  advertising: {
    type: Object,
    default: () => { },
  },
})

const openAccountValue = ref('1') // 账户类型 1: Facebook, 2: TikTok

// 风险等级颜色
const rankColor = (type) => {
  switch (type) {
    case '低':
      return '#00F1A2'
    case '中':
      return '#FFB900'
    case '高':
      return '#F56C6C'
    default:
      return '#00F1A2'
  }
}
</script>

<style lang="less" scoped>
.accountProgress {
  :deep(.el-card__body) {
    min-height: 285px;
    height: 285px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    line-height: 28px;
  }

  .proTitle {
    color: #262626;
    margin: 0;
  }

  .openProgress {
    border-bottom: 1px solid#F0F0F0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .proNum {
        font-size: 16px;
        font-family: DIN-Bold, DIN;
        font-weight: 700;
        color: #262626;
        margin: 0;
      }
    }
  }

  .accountRisk {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
    }

    p {
      margin: 0;
    }

    .rank {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .noneRank {
      color: #ffb900;
    }
  }
}
</style>
