<template>
  <el-card class="quickEntry">
    <template #header>
      <div class="cardHeader">
        <span class="SubHead">快捷入口</span>
      </div>
    </template>
    <div class="cardContent">
      <el-card shadow="hover">
        <div class="item" @click="$router.push('open_account')">
          <img title="开户申请" src="@/assets/images/workSpace/apply.png" />
          <span>
            <div>开户申请</div>
            <div>开户申请</div>
          </span>
        </div>
      </el-card>
      <el-card shadow="hover">
        <div class="item" @click="$router.push('advertising_list')">
          <img title="账户充值" src="@/assets/images/workSpace/recharge.png" /><span>
            <div>账户充值</div>
            <div>账户充值</div>
          </span>
        </div>
      </el-card>
      <!-- <el-card shadow="hover"> -->
      <!-- <div class="item" @click="$router.push('invite')"> -->
      <!-- <img title="邀请特权" src="@/assets/images/workSpace/invite.png" /> -->
      <!-- <span> -->
      <!-- <div>邀请特权</div> -->
      <!-- <div>邀请特权</div> -->
      <!-- </span> -->
      <!-- </div> -->
      <!-- </el-card> -->
      <el-card shadow="hover" v-if="$store.getters.userInfo.exc == 'qbit'">
        <div class="item" @click="$router.push('QbitRecharge')">
          <img title="个号充值" src="@/assets/images/workSpace/singleRecharge.png" />
          <span>
            <div>个号充值</div>
            <div>个号充值</div>
          </span>
        </div>
      </el-card>
    </div>
  </el-card>
</template>

<script setup></script>

<style lang="less" scoped>
.quickEntry {
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cardContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    :deep(.el-card) {
      width: 32%;
      margin-bottom: 47px;
      border: none;
      transition: none;
    }

    :deep(.el-card:hover) {
      margin-bottom: 45px;
      border: 1px solid #e4e7ed;
    }

    :deep(.el-card__body) {
      padding: 0;

      .item {
        box-sizing: border-box;
        display: flex;
        background: url('~@/assets/images/workSpace/quickEntryBg.png') no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 8px 8px 8px 8px;
        padding: 32px 24px 27px 24px;

        img {
          width: 54px;
          height: 54px;
          margin-right: 16px;
        }

        span {
          display: flex;
          flex-direction: column;
          justify-content: center;

          div:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2b82ff;
            line-height: 26px;
            vertical-align: middle;
            cursor: pointer;
          }

          div:nth-child(2) {
            font-size: 14px;
            color: #595959;
            line-height: 22px;
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
