<template>
  <source-data v-if="isQbitSource"/>
  <workspace v-else/>
</template>

<script setup>
import Workspace from "@/views/homepage/Workspace";
import SourceData from "@/views/homepage/SourceData";
import {computed, getCurrentInstance} from "vue";

const {proxy} = getCurrentInstance()
const isQbitSource = computed(() => {
  return proxy.$store.getters.userInfo && proxy.$store.getters.userInfo.q_type
})
</script>


