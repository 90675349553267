<template>
  <el-row>
    <!-- 用户卡片 -->
    <el-col :span="24"><account-card :advertising="advertising" style="margin-bottom: 20px" /></el-col>
  </el-row>
  <el-row :gutter="20" style="margin-bottom: 20px">
    <el-col :span="24">
      <el-row :gutter="20">
        <el-col :span="10">
          <!-- 账户管理 -->
          <account-manage :advertising="advertising" />
        </el-col>
        <el-col :span="6">
          <!-- 开户进度 -->
          <account-progress :advertising="advertising" />
        </el-col>
        <el-col :span="8">
          <!-- 我的资产 -->
          <asset-manage :advertising="advertising" />
        </el-col>
      </el-row>
    </el-col>
    <!-- <el-col :span="8">   -->
    <!-- <!~~ 热门文章 ~~> -->
    <!-- <popular-article :advertising="advertising" /> -->
    <!-- </el-col> -->
  </el-row>

  <el-row :gutter="20">
    <el-col :span="24">
      <!-- 快捷入口 -->
      <quick-entry />
    </el-col>
    <!-- <el-col :span="8"> -->
    <!-- <!~~ 最新活动 ~~> -->
    <!-- <latest-activity :advertising="advertising" /> -->
    <!-- </el-col> -->
  </el-row>
</template>

<script setup>
import api from '@/request/api/homepage'
import { ElCol, ElMessage } from 'element-plus'
import AccountCard from './Workspace/AccountCard.vue'
import AssetManage from './Workspace/AssetManage.vue'
import AccountManage from './Workspace/AccountManage.vue'
import AccountProgress from './Workspace/AccountProgress.vue'
import PopularArticle from './Workspace/PopularArticle.vue'
import QuickEntry from './Workspace/QuickEntry.vue'
import LatestActivity from './Workspace/LatestActivity.vue'
import { getCurrentInstance, onMounted, ref } from 'vue'

const { proxy } = getCurrentInstance()

const getDefaultAdvertising = () => {
  return {
    date: 0, // 服务天数
    bonus_balance: null, // 赠金
    // 商务信息
    salesman: {
      name: null, // 姓名
      phone: null, // 联系方式
      img: null, // 微信二维码
      token: null, // token
    },
    integral: null, // 会员等级
    balance: null, // 钱包余额
    credit_type: null, // 客户属性,1:预付 2:后付
    count: 0, // 账户数量
    fb: {
      balance: null, // 账户余额
      review_num: 0, // 审核中数量
      revise_num: 0, // 待修改数量
      pass_num: 0, // 近7天已通过数量
      active_num: 0, // 活跃账户数量
      blocked_num: 0, // 被封/异常账户数量
    },
    tt: {
      balance: null,
      review_num: 0,
      revise_num: 0,
      pass_num: 0,
      active_num: 0,
      blocked_num: 0,
    },
    gg: {
      balance: null,
      review_num: 0,
      revise_num: 0,
      pass_num: 0,
      active_num: 0,
      blocked_num: 0,
    },
    bing: {
      balance: null,
      review_num: 0,
      revise_num: 0,
      pass_num: 0,
      active_num: 0,
      blocked_num: 0,
    },
    // 热门文章
    pop_article: [
      {
        id: 0,
        name: null,
        time: null,
      },
    ],
    violation: null, // 广告违规风险评估
    // 精选课程/直播/活动
    offline: [
      {
        title: null, // 标题
        thumb: null, // 缩略图
        price_selling: null, // 现价
        price_market: null, // 原价
        url: null, // 跳转链接
        time: null, // 日期
      },
    ],
    // 公告
    message: {
      m_type: 0,
      type: 0,
      id: null,
      content: null,
      title: null,
    },
  }
}
const advertising = ref(getDefaultAdvertising())

// 初始化页面数据
const initPageData = async () => {
  let res = await api.myAdvertising().catch((res) => {
    return res
  })

  if (res.code == 300) {
    return proxy.$router.push('/login')
  }

  if (res.code == 100) {
    ElMessage.closeAll()
    return ElMessage.error({
      message: `${res.msg}`,
      duration: 1000,
      onClose() {
        proxy.$router.push('/clientInfo')
      },
    })
  }

  advertising.value = Object.assign(getDefaultAdvertising(), res && res.data ? res.data : {})
  localStorage.setItem('credit_type', advertising.value.credit_type)
  await getNotice()
}
// 获取公告
const getNotice = async () => {
  if (advertising.value.message.content !== '') {
    let content = `<a href="/message" class="wrapA">${advertising.value.message.content}</a>`
    proxy.$notify({
      title: advertising.value.message.title,
      dangerouslyUseHTMLString: true,
      offset: 60,
      duration: 0,
      type: 'info',
      customClass: 'notice',
      onClose: noticeClose,
      message: content,
    })

    await api.setMessage({
      id: advertising.value.message.id,
      type: 3,
    })
  }
}
// 关闭公告
const noticeClose = async () => {
  const { data: res } = await api.setMessage({
    id: advertising.value.message.id,
    type: 3,
  })
}
// 新用户提示设置密码
const psdTip = async () => {
  if (!window.localStorage.getItem('isPsd')) {
    window.localStorage.setItem('isPsd', 1)
    if (proxy.$store.getters.userInfo.password == '') {
      const confirmResult = await proxy
        .$confirm('现在可以通过密码登陆了，前往设置密码？', '提示', {
          confirmButtonText: '前往',
          cancelButtonText: '跳过',
          type: 'warning',
        })
        .catch((err) => err)

      if (confirmResult !== 'confirm') {
        return
      } else {
        proxy.$router.push('/reset')
      }
    }
  }
}

onMounted(() => {
  initPageData()
  psdTip()
})
</script>

<style lang="less" scoped></style>
